import { Tag, PageHeader } from "antd";
import React from "react";

export default function Header() {
  return (
    <a href="https://harvest.art/" target="_blank" rel="noopener noreferrer">
      <PageHeader
        title="Harvest.art"
        subTitle="Sell for dirt. Reap the harvest."
        style={{ cursor: "pointer" }}
        avatar={{ src: "corn.png" }}
      />
    </a>
  );
}
