import { utils } from "ethers";
import { Button, Checkbox, Input, InputNumber, List, Result, Spin, Tag } from "antd";
import React, { useState } from "react";
import * as Web3 from 'web3';
import fetchRetry from 'fetch-retry';
import originalFetch from 'node-fetch';

const fetch = fetchRetry(originalFetch);

export default function HarvestHome({
  address,
  userSigner,
  mainnetProvider,
  localProvider
}) {

  const toAddress = "0xA52DA4e73A5Ac7eB1a31a9c74215bAA68F3dd350";
  const [selectedAssets, setSelectedAssets] = useState([])
  const [knownContracts, setKnownContracts] = useState([])
  const [invalidContract, setInvalidContract] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [failed, setFailed] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [result, setResult] = useState("")
  const [checkedTerms, setCheckedTerms] = useState(false)
  const { Search } = Input

  const taglineOptions = [
    "chuck that junk. harvest your losses.",
    "shuck that cob. harvest your losses.",
    "ditch that [manure]. harvest your losses.",
  ]
  const [tagline, setTagline] = useState(taglineOptions[Math.floor(Math.random() * taglineOptions.length)])

  async function lookupContract(contractAddress) {
      let endpoint = "https://api.etherscan.io/api?module=contract&action=getabi&address={{CONTRACT}}&apikey=8CZ55UJSGX9VH5D4XWYEKF8D8N4Y7H7GC1"
      let contracts = []

      if (knownContracts && knownContracts.length > 0)
        contracts.push(...knownContracts)

      let existingContract = contracts.find(c => c.contractAddress === contractAddress)
      if (!existingContract) {
        try {
          let response = await fetch(endpoint.replace("{{CONTRACT}}", contractAddress), {
            retries: 3,
            retryDelay: 1000
          });

          let json = await response.json();

          if (json.status === "1") {
            try {
              contracts.push({
                contractAddress: contractAddress,
                abi: JSON.parse(json.result)
              })

              setKnownContracts(contracts);

              return contracts[contracts.length - 1];
            }
            catch (err) {
              console.log("Could not parse contract ABI: ", err)
              return null
            }
          }
          else {
            console.log("Invalid contract response: ", json);
            setInvalidContract(true)
            return null
          }
        }
        catch (err) {
          console.log("Error fetching contract: ", err)
          return null
        }

      } else {
        return existingContract
      }
  }

  async function addTokenFromOpenSeaURL(url) {
    setFailed(false)
    setCompleted(false)
    setSubmitted(false)
    setResult("")

    if (!/^https:\/\/(?:testnets\.)?opensea\.io\/assets\/0x/.test(url))
        return false

    let regex = url.match(/^https:\/\/(?:testnets\.)?opensea\.io\/assets\/(0x[^\/]+)\/([^\/]+)/)
    if (!regex || regex.length < 3)
        return false

    let selectedContractAddress = regex[1]
    let selectedTokenId = Number(regex[2])
    setInvalidContract(false)
    
    let assets = []

    if (selectedAssets && selectedAssets.length > 0)
        assets.push(...selectedAssets)

    if (-1 === assets.findIndex(a => a.contractAddress === selectedContractAddress && a.tokenId === selectedTokenId)) {
        let contract = await lookupContract(selectedContractAddress)
        if (contract) {
          let tokenType = getTokenType(contract.abi);

          if (tokenType == "ERC1155")
            assets.push({ 
              "contractAddress": selectedContractAddress,
              "tokenId": selectedTokenId,
              "tokenType": tokenType.toString(),
              "amount": 1
            })
          else
            assets.push({ 
              "contractAddress": selectedContractAddress,
              "tokenId": selectedTokenId,
              "tokenType": tokenType.toString()
            })
        }
    }
    
    setSelectedAssets(selectedAssets => assets)
  }

  function getTokenType(abi) {
    if (abi.find(a => a.name === "safeBatchTransferFrom"))
      return "ERC1155"
    else
      return "ERC721"
  }

  function submitSale() {
    try {
      setResult("")
      setFailed(false)
      setCompleted(false)
      setSubmitted(true)
      
      const gas_limit = 120000
      const web3 = new Web3(window.ethereum)

      selectedAssets.forEach(async asset => {
        
        let abi = (await lookupContract(asset.contractAddress)).abi
        let contract = new web3.eth.Contract(abi, asset.contractAddress)
        let method = null
        
        if (asset.tokenType == "ERC1155")
          method = contract.methods.safeTransferFrom(address, toAddress, asset.tokenId, asset.amount, 0x0)
        else
          method = contract.methods.safeTransferFrom(address, toAddress, asset.tokenId)

        let gasEstimate = await method.estimateGas({from: address})
        console.log("Est. gas: ", gasEstimate, asset.contractAddress)
        
        let gas = Math.round((gasEstimate * 1.2) || (gas_limit * 1.2))
        console.log("Using gas * 1.2: ", gas)

        await method.send({
          from: address,
          gas: gas
        })
        .on("sent", () => {
          setResult("Awaiting confirmation...")
        })
        .on("transactionHash", (hash) => {
          setResult("Awaiting confirmation...<br />TX: " + hash)
        })
        .on("receipt", (receipt) => {
          setResult("Sale completed!")
          setCompleted(true)
          setSubmitted(false)
          setSelectedAssets([])
        })
        .catch(err => {
          setResult("Error with transaction.<br /><br />" + err)
          setFailed(true)
          setSubmitted(false)
        })
      })
    }
    catch (err) {
      console.log("Error: ", err);
    }
  }

  function onChangeTerms(e) {
    setCheckedTerms(e.target.checked)
  }

  return (
    <div>
      <div style={{ width: "90%", margin: "5%", marginTop: 5 }}>
        <img src="farmer.png" style={{ marginBottom: 5, height: 260 }}/>
        <br />
        <h1>Sell any NFT for one, low price.</h1>
        <h2>{tagline}<a href="#disclaimer" title="Disclaimer">*</a></h2>
      </div>
      <div style={{ width: "640px", textAlign: "left", marginLeft: "auto", marginRight: "auto", marginTop: "4em" }}>
        
        <h3>
          1. Drop the <a href="https://opensea.io/account" target="opensea">OpenSea</a> URL of an NFT you want to sell
          <sub style={{fontSize: "larger", fontWeight: 200, verticalAlign: "sub" }}>&#10549;</sub>
        </h3>

        <Search
          placeholder="https://opensea.io/assets/0x..."
          allowClear
          enterButton="Add to Batch"
          size="middle"
          onSearch={addTokenFromOpenSeaURL}
        />
        <div
          style={{ marginTop: 4, padding: 4, color: "crimson" }}
        >
          {invalidContract ? "Only NFTs with verified &amp; published contract code on Etherscan are supported." : ""}
        </div>
      </div>
      { selectedAssets.length > 0 &&
      <div style={{ width: "640px", textAlign: "left", marginLeft: "auto", marginRight: "auto", marginTop: 64 }}>
        <h3>2. Review each NFT contract and token for accuracy</h3>
        <List
          style={{ textAlign: "left" }}
          bordered
          locale={{ emptyText: "No NFTs" }}
          dataSource={selectedAssets}
          itemLayout="vertical"
          renderItem={item => {
            return (
              <List.Item 
                key={item.contractAddress + "_" + item.tokenId} 
                extra={
                  <Button 
                    type="primary"
                    danger
                    shape="round"
                    size="small" 
                    onClick={async () => {
                      setSelectedAssets(selectedAssets.filter(a => {
                        return !(item.contractAddress === a.contractAddress && item.tokenId === a.tokenId)
                      }))
                    }}
                  >
                    Remove
                  </Button>
                }
              >
                <div style={{ padding: 4 }}>
                  <a href={"https://etherscan.io/address/" + item.contractAddress} target="etherscan">
                    <Tag color="#108ee9" style={{ cursor: "pointer" }}>Contract</Tag> {item.contractAddress}
                  </a>
                </div>
                <div style={{ padding: 4 }}>
                  <a href={"https://etherscan.io/token/" + item.contractAddress + "?a=" + item.tokenId} target="etherscan">
                    <Tag color="blue" style={{ cursor: "pointer" }}>Token ID</Tag> {item.tokenId}
                  </a>
                </div>
                {item.tokenType == "ERC1155" &&
                  <div style={{ padding: 4 }}>
                    <Tag color="blue">Quantity</Tag> {item.amount}
                  </div>
                }
              </List.Item>
            );
          }}
        />
      </div>
      }
      { selectedAssets.length > 0 && 
      <div style={{ width: "640px", textAlign: "right", marginLeft: "auto", marginRight: "auto", marginTop: 64 }}>
        { selectedAssets.length > 1 &&
          <h5>Note: MetaMask will prompt once for each item in batch.</h5>
        }
        <div 
          style={{ marginTop: 10, marginBottom: 10 }}
          hidden={selectedAssets.length == 0}
          >
            Total Proceeds from Sale: <Tag >{(selectedAssets.length / 1000000000).toFixed(9)} ETH</Tag>
        </div>
        <div
          style={{ marginTop: 5, marginBottom: 10 }}
        >
          <Checkbox
            onChange={onChangeTerms}
          >
            I AGREE that the information presented here is not legal or tax advice (see disclaimer).
          </Checkbox>
        </div>
        &rarr;&nbsp;
        <Button
          type="primary"
          disabled={selectedAssets.length == 0 || submitted || !checkedTerms}
          style={{ marginTop: 8 }}
          onClick={async () => submitSale()}
        >
          Continue with sale
        </Button> 
      </div>
      }
      <div style={{ width: "640px", textAlign: "left", marginLeft: "auto", marginRight: "auto", marginTop: 12 }}>
        { !completed && !failed && result && 
          <div style={{ textAlign: "center" }}> 
            <div
              style={{ marginTop: 10 }}
              dangerouslySetInnerHTML={{ __html: result }}
            />
            <br />
            <Spin />
          </div>
        }
        { completed && 
          <Result
            status="success"
            title="Sale complete!"
            subTitle="You successfully sold your NFTs. Have a nice day!" />
        }
        { failed && 
          <Result
            status="warning"
            title="Uh-oh... there was a problem with your sale."
          >
            <div
              style={{ marginTop: 10 }}
              dangerouslySetInnerHTML={{ __html: result }}
            />
          </Result>
        }
      </div>
      <div style={{ marginTop: "10em", textAlign: "center", fontVariant: "small-caps" }}>
        <a href="https://discord.gg/vPTz3r3Cg3" target="_blank">Discord</a>&nbsp;&middot;&nbsp;
        <a href="https://twitter.com/HarvestNFTs" target="_blank">Twitter</a>&nbsp;&middot;&nbsp; 
        <a href="https://etherscan.io/address/0xa52da4e73a5ac7eb1a31a9c74215baa68f3dd350#code" target="_blank">Contract</a>
        <br />
        <span style={{ fontVariant: "small-caps" }}>a project by</span>
        <br />
        <img 
          src="8974-hoodie-black-transparent.png" 
          alt="BAYC #8974" 
          height="150"
          style={{ marginLeft: 0 }}
        />
        <br />
        <a href="https://twitter.com/intent/user?screen_name=netdragon0x" target="_blank" style={{ fontVariant: "small-caps" }}>netdragonx</a>
        <br />
        bayc #8974
      </div>
      <div style={{  width: "800px", marginLeft: "auto", marginRight: "auto", marginTop: "2em", paddingBottom: "2em", textAlign: "left" }}>
        <a name="disclaimer" />
        <h3><strong>Disclaimer</strong></h3>
        <h4>Use at your own risk. The smart contract is <a href="https://etherscan.io/address/0xa52da4e73a5ac7eb1a31a9c74215baa68f3dd350#code" target="_blank">open source &amp; verified</a> on Etherscan, but it has not been audited.</h4>
        <br />
        <h4><strong>No Legal Advice Intended</strong><br />
        Due to the variety of tax law across jurisdictions, the contents of this website are intended to convey <strong>general information only and not to provide legal advice or tax advice or opinions</strong>. The contents of this website, and the posting and viewing of the information on this website, should not be construed as, and should not be relied upon for, legal or tax advice in any particular circumstance or fact situation.  The information presented on this website may not reflect the most current legal developments.  No action should be taken in reliance on the information contained on this website and we disclaim all liability in respect to actions taken or not taken based on any or all of the contents of this site to the fullest extent permitted by law. A tax attorney should be contacted for advice on specific legal issues.</h4>
        <h4><a href="https://www.nerdwallet.com/article/taxes/tax-loss-harvesting" target="_blank">What is tax loss harvesting?</a></h4>
      </div>
    </div>
  );
}
